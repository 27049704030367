import React, { useState } from "react"

import Page from "../../components/layout/Page"
import SEO from "../../components/seo"
import SimpleForm from "../../components/forms/SimpleForm"
import SuccessBox from "../../components/boxes/SuccessBox"
import EmailField from "../../components/fields/EmailField"

import { postData } from "../../services/server"
import { isEmailAddress } from "../../utilities/validation";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [validation, setValidation] = useState({})
  const [busy, setBusy] = useState(false);
  const [sent, setSent] = useState(false);

  function forgotPassword() {
    const problems = { error: "" }

    if (email.length === 0)
      problems.email = "Required";
    if (!isEmailAddress(email))
      problems.email = "Invalid";

    setValidation(problems);

    if (problems.email)
        setError("Please correct the highlighted problems.");
    else {
      setBusy(true);

      postData("/api/users/forgotpassword", { email: email },
        (response) => {
            setSent(true);
        },
        (error) => {
          console.log(error);
          setError("Error processing request.")
        },
        () => {
          setBusy(false);
        }
      );
      }
  }

  return (
    <>
      <SEO page="forgot-password" />
      <Page simple center>
        <>
        {sent ? 
            <>
              <SuccessBox>
                  We sent an email to <strong>{email}</strong> with
                  instructions for resetting your password.
              </SuccessBox>
              <p>
                  If you don't see the email in a few minutes, please check 
                  your SPAM folder.
              </p>
            </>
        :
          <SimpleForm
            title="Forgot Password"
            instructions="Enter your email address below and we'll send you instructions on how to reset your password."
            error={error}
            busy={busy}
            disabled={email===""}
            onClick={forgotPassword}
        >
            <div>
                <EmailField
                    name="email"
                    label="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} 
                    wide
                    error={validation.password ? true:false}
                    autoComplete="email"
                />
            </div>
        </SimpleForm>
        }

        <br />
        <br />
        </>         
      </Page>
    </>
  )
}

export default ForgotPasswordPage
