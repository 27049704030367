import React from "react"
import styled from 'styled-components'

import BlueButton from "../buttons/BlueButton"
import ErrorBox from "../boxes/ErrorBox"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Form = styled.form`
    max-width: 450px;


    > div {
        button {
            margin-top: 25px;
            display: flex;
            flex-flow: row-reverse;
            align-items: center;

            svg {
                margin-left: 8px;
            }
        }
    }
`
const Title = styled.h2`
`
const Instructions = styled.div`
    margin: 15px 0px 30px 0px;
`

const SimpleForm = (props) => {
  return (
    <Form onSubmit={() => {return false;}}>
        <Title>{props.title}</Title>
        <Instructions>{props.instructions}</Instructions>

        {props.children}

        { props.error && 
            <ErrorBox style={{margin: "15px 0px"}}>
                {props.error}
            </ErrorBox>
        }


        <div align="center">
            <BlueButton 
                label="Continue" 
                size="large"
                busy={props.busy} 
                disabled={props.disabled}
                onClick={props.onClick} 
                icon={faChevronRight}
            />
        </div>
    </Form>
  )
}

export default SimpleForm
